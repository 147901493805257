<template>
  <div>
    <v-row class="pa-3">
      <v-col>
        <v-progress-linear
          v-if="carregando"
          color="primary"
          indeterminate
        />

        <i v-if="acompanhamentos.length <= 0 && !carregando">
          Nenhuma informação encontrada
        </i>

        <v-timeline
          v-if="!carregando"
          align-top
          dense
        >
          <v-timeline-item
            v-for="(acompanhamento, key) in acompanhamentos"
            :key="key"
            color="info"
            small
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{ acompanhamento.data_registro | data('DD/MM/YYYY HH:mm:ss') }}</strong>
              </v-col>
              <v-col>
                <strong>{{ acompanhamento.placa }}</strong>
                <div class="caption">
                  {{ acompanhamento.descricao_camera }} - {{ acompanhamento.descricao_ponto_captura }}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {

    props: {
      ocorrencia: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        carregando: false,
        acompanhamentos: [],
      }
    },

    mounted () {
      this.buscarAcompanhamentos()
    },

    methods: {

      async buscarAcompanhamentos () {
        try {
          this.carregando = true

          const resposta = await ocorrenciasApi.acompanhamentos(this.ocorrencia.id)

          
          this.acompanhamentos = resposta.data
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

    },
  }
</script>
